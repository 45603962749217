<template>
	<div class="pb-21">
		<Hero
			:media="page.promotedEvent[0].media[0]"
			:title="page.promotedEvent[0].title"
			:dateFrom="page.promotedEvent[0].dateFrom"
			:dateTo="page.promotedEvent[0].dateTo"
			:stage="page.promotedEvent[0].stage[0].title"
			:theme="page.theme"
			:imageLayout="page.imageLayout"
			:url="`/event/${page.promotedEvent[0].slug}`"
			v-if="page && page.promotedEvent.length > 0"
		/>

		<template v-if="page">
			<template v-for="(entry, index) in page.sections" :key="index">
				<component :is="entry.__typename.replace('_Entry', '')" :data="entry"/>
			</template>
		</template>

	</div>
</template>

<script setup>
import { homeQuery } from '@/api/queries'
import { onMounted } from 'vue';
const config = useRuntimeConfig();
const route = useRoute();

const page = ref(null)

// Build the URL, with `query` and `token` params:
const query = homeQuery()
const token = route.query.token;

const getData = async () => {
	const { data: gql } = await useFetch(config.public.API, {
		params: { query, token },
	});

	// Extract the first page from the response:
	if (gql.value) {
		page.value = gql.value.data.homeEntries[0];
	}
}

await getData();

onMounted(() => {
	if (!page.value) {
		getData();
	}
})

useHead({
	title: `Sydhavn Teater`,
	meta: [
		{ hid: 'og:title', property: 'og:title', content: `Sydhavn Teater` },
		{ hid: 'og:image', property: 'og:image', content: page?.value?.promotedEvent[0]?.media[0]?.url },
	],
});
</script>